import { useMeQuery } from "@chef/state-management";
import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import TabBar from "./styles";
import { Image } from "../../primitives";

const BLACKLIST = [
  /.*app\/leveringer\/historikk\/\[orderId\]\/reklamasjon.*/,
  /.*app\/min-konto\/mine-matvaner.*/,
];

const TabBarComponent = ({ items = [] }) => {
  const { data: isLoggedIn } = useMeQuery();
  const router = useRouter();

  if (!isLoggedIn) {
    return null;
  }

  if (BLACKLIST.some((regex) => regex.test(router.pathname))) {
    return null;
  }

  const active =
    items.find((item) => item.path === router.pathname) ||
    items.find((item) => router.pathname.startsWith(item.path)) ||
    {};

  return (
    <div className="md:hidden">
      <TabBar className="z-10 print:hidden">
        <TabBar.Tabs>
          {items.map((item) => (
            <Link
              key={item.path}
              href={item.path && item.path}
              passHref
              legacyBehavior
            >
              <TabBar.Item
                className={active.path === item.path && "nav-item-active"}
                onClick={item.action && item.action}
              >
                <TabBar.ImageWrapper>
                  <Image
                    src={`/assets/icons/40-icons/${item.icon}.svg`}
                    alt={item.shortName}
                  />
                  {item.highlight && <TabBar.NotificationDot />}
                </TabBar.ImageWrapper>
                <TabBar.Text>{item.shortName}</TabBar.Text>
              </TabBar.Item>
            </Link>
          ))}
        </TabBar.Tabs>
      </TabBar>
      <TabBar.Spacer />
    </div>
  );
};

export default TabBarComponent;
