/* eslint-disable unused-imports/no-unused-vars */
import React from "react";
import styled, { css } from "styled-components";
import MagicLink from "../../components/MagicLink";
import { media } from "../../global-styles";

const closeRedSvg = "/assets/icons/close-inverted.svg";
// TEMP
// Simple filter to remove unwanted props from the native <a> element
// -> https://github.com/styled-components/styled-components/issues/439
/* eslint-disable no-unused-vars */
const ButtonLinkFilter = ({
  primary = false,
  active = false,
  small = false,
  disabled = false,
  full = false,
  text = false,
  weak = false,
  large = false,
  routerLink = false,
  circle = false,
  bordered = false,
  noBorder = false,
  noPadding = false,
  transparent = false,
  selected = false,
  dark = false,
  ...props
}) => <MagicLink {...props} />;
/* eslint-enable */

const Button = styled.button`
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 36px;
  // min-width: 90px;
  padding: 0 14px;
  color: ${(props) => props.theme.colors.dark};
  background: white;
  border: 2px solid ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.typography.fontFamily};
  text-align: ${(props) => props.theme.general.buttonTextAlign};
  border-radius: ${(props) => props.theme.general.buttonBorderRadius};
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
  > * {
    vertical-align: baseline;
    pointer-events: none !important;
  }

  ${media.teen`
    font-size: 16px;
    min-height: 40px;
  `}

  /* Primary variant */
  ${(props) =>
    props.primary &&
    css`
      color: ${props.theme.colors.buttonTextPrimary};
      background-color: ${props.theme.colors.primary};
    `};
  /* PrimaryDark variant */
  ${(props) =>
    props.primaryDark &&
    css`
      color: ${props.theme.colors.buttonTextPrimary};
      background-color: ${props.theme.colors.primaryDark};
      border: 2px solid ${(props) => props.theme.colors.primaryDark};
    `};
  /* Transparent variant */
  ${(props) =>
    props.transparent &&
    css`
      background: none !important;
    `};
  /* Set width */
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
  /* Special variant */
  ${(props) =>
    props.large &&
    css`
      font-size: 20px;
      line-height: 56px;
      min-height: 56px;
    `};
  /* Primary variant */
  ${(props) =>
    props.special &&
    css`
      color: ${props.theme.colors.buttonTextPrimary};
      background-color: ${props.theme.colors.special};
      border-color: ${props.theme.colors.special} !important;
    `};
  ${(props) =>
    props.specialWhiteBg &&
    css`
      color: ${props.theme.colors.dark};
      background-color: ${props.theme.colors.light};
      border-color: ${props.theme.colors.special};
    `};
  /* Secondary variant */
  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.colors.dark};
      border-color: ${props.theme.colors.secondary};
    `};
  /* Active variant */
  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.colors.dark};
      background-color: ${props.theme.colors.secondary};
    `};
  /* weak variant */
  ${(props) =>
    props.weak &&
    css`
      border-color: ${props.theme.colors.darkBeige};
    `};
  /* weaker variant */
  ${(props) =>
    props.weaker &&
    css`
      border-color: ${props.theme.colors.darkBeige};
      background-color: ${props.theme.colors.lightBeige};
    `};
  /* weaker variant */
  ${(props) =>
    props.lightBeige &&
    css`
      border-color: ${props.theme.colors.primary};
      background-color: ${props.theme.colors.lightBeige};
    `};
  /* Disabled variant */
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      ${(props) =>
        !props.loader &&
        css`
          color: ${props.theme.colors.darkBeige} !important;
          background-color: ${props.theme.colors.light} !important;
          border-color: ${props.theme.colors.lightBeige} !important;
        `};
    `};
  /* Secondary variants */
  ${(props) =>
    props.secondary === "fiber" &&
    css`
      background-color: ${props.theme.colors.fiber};
      border-color: ${props.theme.colors.fiber};
    `};
  /* Full-width variant */
  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `};
  ${(props) =>
    props.wide &&
    css`
      width: 344px;
    `};
  /* Centered variant */
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};
  /* noPadding variant */
  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `};
  ${(props) =>
    props.circle &&
    css`
      padding: 0;
      min-width: 0;
      line-height: 36px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    `};
  ${(props) =>
    props.bordered &&
    css`
      background-color: transparent;
      color: ${props.theme.colors.light};
    `};
  ${(props) =>
    props.facebook &&
    css`
      color: #ffffff;
      background-color: #3b5998;
      border: solid 2px #3b5998;
    `};
  ${(props) =>
    props.messenger &&
    css`
      color: #ffffff;
      background-color: #0084ff;
      border: solid 2px #0084ff;
    `};
  ${(props) =>
    props.box &&
    css`
      border-radius: 2px;
      color: white;
      background-color: ${props.theme.colors.primary};
      &::before {
        content: "";
        display: inline-block;
        margin-right: 13px;
        position: relative;
        top: 2.4px;
        width: 13px;
        height: 13px;
        background-image: url(${closeRedSvg});
      }
    `};
  ${(props) =>
    props.redClose &&
    css`
      padding: 0 40px;
      color: white;
      background-color: ${props.theme.colors.primary};
      &::before {
        content: "";
        display: inline-block;
        margin-right: 3px;
        position: relative;
        top: 2.3px;
        width: 14px;
        height: 14px;
        background-image: url(${closeRedSvg});
      }
    `};
  ${(props) =>
    props.tinyBorderRadius &&
    css`
      border-radius: ${props.theme.general.buttonBorderRadiusTiny};
      height: ${props.theme.general.minButtonHeight};
      font-size: 20px;
    `};
  ${(props) =>
    props.flexIcon &&
    css`
      display: inline-flex;
      justify-content: center;
    `};
  ${(props) =>
    props.noBorder &&
    css`
      border: none !important;
    `};
  ${(props) =>
    props.opacity &&
    css`
      color: ${props.theme.colors.buttonTextPrimary};
      background-color: ${props.theme.colors.primary};
      opacity: 0.6;
      border: none;
    `};
  ${(props) =>
    props.widePadding &&
    css`
      padding: 0 40px;
    `};
  ${(props) =>
    props.hidden &&
    css`
      visibility: hidden;
    `};
  ${(props) =>
    props.selected &&
    css`
      padding: 0px 14px 0px 0px;
    `};

  ${(props) =>
    props.filter &&
    !props.selected &&
    css`
      :hover {
        background-color: ${props.theme.colors.primary};
        border-color: ${props.theme.colors.primary};
        span {
          color: ${props.theme.colors.light} !important;
        }
      }
    `}

  ${(props) =>
    props.fiber &&
    css`
      background-color: ${props.theme.colors.fiber};
    `};

  ${(props) =>
    props.light &&
    css`
      background-color: transparent;
      color: ${props.theme.colors.light};
      border: 2px solid ${props.theme.colors.light};
    `};
`;

const ButtonLink = Button;
const ButtonFake = Button;
const ButtonRouterLink = Button;

export { Button, ButtonLink, ButtonFake, ButtonRouterLink };
